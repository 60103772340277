import React from "react";
import { IframeCardMedia } from "../Resources/IframeCard";

function FullVideoComponent({ blockInfo }) {
  return (
    <div class="relative w-full h-0 pb-[54.1%] pt-[2.2%]">
    <iframe
      src={`https://player.vimeo.com/video/${blockInfo?.vimeoVideoId}`}
      class="absolute top-0 left-0 w-full h-full m-0 p-0"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  );
}

export default FullVideoComponent;

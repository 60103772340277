import { useMediaQuery } from '@mui/material';

export default function TwoSizeImage({ blockInfo }) {
    const { imageWide, imageNarrow } = blockInfo;
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <div className='relative mx-auto max-w-7xl py-1 px-6 lg:px-8'>
            {isMobile ? <img src={imageNarrow.url} style={{ maxWidth: '100%', width: imageNarrow.width }} alt={imageNarrow.title} /> : <img src={imageWide.url} style={{ maxWidth: '100%', width: imageWide.width }} alt={imageWide.title} />}
        </div>
    );
}

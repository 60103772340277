import dynamic from 'next/dynamic';

const Accordions = dynamic(() => import('../../components/ArticleBlocks/Accordions'));
const BackgroundImageHeader = dynamic(() => import('../../components/ArticleBlocks/BackgroundImageHeader'));
const BackgroundVideoHeader = dynamic(() => import('../../components/ArticleBlocks/BackgroundVideoHeader'));
const ConferenceInfoStrip = dynamic(() => import('../../components/ArticleBlocks/ConferenceInfoStrip'));
const HeaderThreeCards = dynamic(() => import('../../components/ArticleBlocks/HeaderThreeCards'));
const HeroAngledImageOnRight = dynamic(() => import('../../components/ArticleBlocks/HeroAngledImageOnRight'));
const Iframe = dynamic(() => import('../../components/ArticleBlocks/Iframe'));
const ImageCarousel = dynamic(() => import('../../components/ArticleBlocks/ImageCarousel'));
const ImageWithDetailOverlay = dynamic(() => import('../../components/ArticleBlocks/ImageWithDetailOverlay'));
const MediaObject = dynamic(() => import('../../components/ArticleBlocks/MediaObject'));
const ModalButton = dynamic(() => import('../../components/ArticleBlocks/ModalButton'));
const ResourceThreeColumn = dynamic(() => import('../../components/ArticleBlocks/ResourceThreeColumn'));
const RichText = dynamic(() => import('../../components/ArticleBlocks/RichText'));
const SimpleDivider = dynamic(() => import('../../components/ArticleBlocks/SimpleDivider'));
const SimpleHeader = dynamic(() => import('../../components/ArticleBlocks/SimpleHeader'));
const SimpleLogoStrip = dynamic(() => import('../../components/ArticleBlocks/SimpleLogoStrip'));
const SimpleStringCarousel = dynamic(() => import('../../components/ArticleBlocks/SimpleStringCarousel'));
const StatCard = dynamic(() => import('../../components/ArticleBlocks/StatCard'));
const SpeakerCards = dynamic(() => import('../../components/ArticleBlocks/SpeakerCards'));
const SpeakerRoundImagesGrid = dynamic(() => import('../../components/ArticleBlocks/SpeakerRoundImagesGrid'));
const TestimonialSlider = dynamic(() => import('../../components/ArticleBlocks/TestimonialSlider'));
const TestimonialSpeaker = dynamic(() => import('../../components/ArticleBlocks/TestimonialSpeaker'));
const TwoColumnImage = dynamic(() => import('../../components/ArticleBlocks/TwoColumnImage'));
const TwoColumnImages = dynamic(() => import('../../components/ArticleBlocks/TwoColumnImages'));
const TwoColumnVideo = dynamic(() => import('../../components/ArticleBlocks/TwoColumnVideo'));
const TwoColumnTextGrid = dynamic(() => import('../../components/ArticleBlocks/TwoColumnTextGrid'));
const TwoTiersPricing2 = dynamic(() => import('../../components/ArticleBlocks/TwoTiersPricing2'));
const SimpleButton = dynamic(() => import('../../components/ArticleBlocks/SimpleButton'));

import {
    ARTICLE_BLOCKS_TYPE,
    FRONT_END_COMPONENT_MAP
} from './model';
import TwoSizeImage from '../../components/ArticleBlocks/TwoSizeImage';
import FullVideoComponent from '../../components/ArticleBlocks/FullVideoComponent';

export const showArticleBlock = (item, index) => {
    switch (item.type) {
        case ARTICLE_BLOCKS_TYPE.HEADER_IMAGE:
            return (
                <div class="mt-5">
                    <div key={index} className='py-2 rounded-xl mx-auto max-w-7xl px-0' style={{ backgroundColor: item.backgroundColor }}>
                        <img
                            key={index}
                            className="h-full w-full object-cover"
                            src={item.image.url}
                            alt={item.image.title}
                        />
                    </div>
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.RICHTEXT:
            return (
                <div key={index} style={{ backgroundColor: item.backgroundColor }}>
                    <RichText key={index} content={item.html} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.SIMPLE_DIVIDER:
            return (
                <div key={index} className='mt-7'>
                    <SimpleDivider blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.FRONT_END_COMPONENT:
            return (
                <div key={index} className=''>
                    {FRONT_END_COMPONENT_MAP[item.componentName]}
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.HEADER_THREE_CARDS:
            return (
                <div key={index} className=''>
                    <HeaderThreeCards blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.RESOURCE_THREE_COLUMN:
            return (
                <div key={index} className='' style={{ backgroundColor: item.backgroundColor }}>
                    <ResourceThreeColumn blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.IMAGE_WITH_DETAIL_OVERLAY:
            return (
                <div key={index} className=''>
                    <ImageWithDetailOverlay blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.HERO_ANGLED_IMAGE_ON_RIGHT:
            return (
                <div key={index} className=''>
                    <HeroAngledImageOnRight blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.SIMPLE_LOGO_STRIP:
            return (
                <div key={index} className=''>
                    <SimpleLogoStrip blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.TWO_COLUMN_VIDEO:
            return (
                <div key={index} className=''>
                    <TwoColumnVideo blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.TWO_COLUMN_TEXT_GRID:
            return (
                <div key={index} className=''>
                    <TwoColumnTextGrid blockInfo={item} />
                </div>
        );
        case ARTICLE_BLOCKS_TYPE.FULL_VIDEO:
            return (
              <div key={index} className=''>
                  <FullVideoComponent blockInfo={item} />
              </div>
            );
        case ARTICLE_BLOCKS_TYPE.TWO_COLUMN_IMAGE:
            return (
                <div key={index} className=''>
                    <TwoColumnImage blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.TWO_COLUMN_IMAGES:
            return (
                <div key={index} className=''>
                    <TwoColumnImages blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.STAT_CARD:
            return (
                <div key={index} className=''>
                    <StatCard blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.TESTIMONIAL_SLIDER:
            return (
                <div key={index} className=''>
                    <TestimonialSlider blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.TESTIMONIAL_SPEAKER:
            return (
                <div key={index} className=''>
                    <TestimonialSpeaker blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.SIMPLE_HEADER:
            return (
                <div key={index} className=''>
                    <SimpleHeader blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.IMAGE_CAROUSEL:
            return (
                <div key={index} className=''>
                    <ImageCarousel blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.ACCORDION:
            return (
                <div key={index} className=''>
                    <Accordions blockInfo={item} />
                </div>
            )
        case ARTICLE_BLOCKS_TYPE.MEDIA_OBJECT:
            return (
                <div key={index} className=''>
                    <MediaObject blockInfo={item} />
                </div>
            )
        case ARTICLE_BLOCKS_TYPE.SIMPLE_BUTTON:
            return (
                <div key={index} className='p-5' style={{ backgroundColor: item?.backgroundColor }}>
                    <SimpleButton blockInfo={item.button} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.IFRAME:
            return (
                <div key={index} className='h-screen'>
                    <Iframe blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.BACKGROUND_IMAGE_HEADER:
            return (
                <div key={index}>
                    <BackgroundImageHeader blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.BACKGROUND_VIDEO_HEADER:
            return (
                <div key={index}>
                    <BackgroundVideoHeader blockInfo={item} />
                </div>
            );       
        case ARTICLE_BLOCKS_TYPE.SPEAKER_CARDS:
            return (
                <div key={index}>
                    <SpeakerCards blockInfo={item} />
                </div>
            );
        case ARTICLE_BLOCKS_TYPE.MODAL_BUTTON:
            return (
                <div key={index}>
                    <ModalButton blockInfo={item} />
                </div>
            )
        case ARTICLE_BLOCKS_TYPE.SPEAKER_ROUND_IMAGES_GRID:
            return (
                <div key={index}>
                    <SpeakerRoundImagesGrid blockInfo={item} />
                </div>
            )
        case ARTICLE_BLOCKS_TYPE.TWO_TIERS_PRICING:
            return (
                <div key={index}>
                    <TwoTiersPricing2 blockInfo={item} />
                </div>
            )
        case ARTICLE_BLOCKS_TYPE.CONF_INFO_STRIP:
            return (
                <div key={index}>
                    <ConferenceInfoStrip blockInfo={item} />
                </div>
            )
        case ARTICLE_BLOCKS_TYPE.SIMPLE_STRING_CAROUSEL:
            return (
                <div key={index}>
                    <SimpleStringCarousel blockInfo={item} />
                </div>
            )
        case ARTICLE_BLOCKS_TYPE.TWO_SIZE_IMAGE:
            return (
                <div key={index} className=''>
                    <TwoSizeImage blockInfo={item} />
                </div>
            )
    }
}

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

export default function PDFViewer(props) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [
            // Remove the attachments tab (\`defaultTabs[2]\`)
            // defaultTabs[0], // Bookmarks tab
            // defaultTabs[1], // Thumbnails tab
        ],
    });

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
            <Viewer 
                fileUrl={props?.fileUrl}
                plugins={[defaultLayoutPluginInstance]}
            />
        </Worker>
    )
}

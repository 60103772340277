import React, {
    useContext,
    useEffect,
    useState
} from 'react';

import {
    Card,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
    Favorite,
    FavoriteBorder
} from '@mui/icons-material';

import parse from 'html-react-parser';

import { RequestNextApi } from '../../api/Request';
import { getCookie } from '../../utils/cookieHandle';
import { ShowNotification } from '../../utils/notification/snackbarUtils';
import { ProgressContext } from '../Context/progressContext';
import PDFViewer from '../PDFViewer';

export const IframeWrapper = styled('div')(({ mobile }) => {
    return ({
        paddingTop: '56.25%',
        background: 'url(/static/images/transparentspinner.gif) center center no-repeat',
        height: "100%",
        width: "100%",
        display: "flex",
    })
});

export const IframeCardMedia = (props) => {
    const { src, alt } = props;
    return (
        <div style={{
            position: "relative",
            backgroundColor: 'transparent',
            width: "100%",
            height: "100%",
            borderColor: "transparent",
            paddingBottom: "56.25%",
            background: 'url(/static/images/transparentspinner.gif) center center no-repeat',
        }}>
            <iframe
                alt={alt}
                allowfullscreen="true"

                // ref={observe}
                style={{
                    webkitallowfullscreen: true,
                    mozallowfullscreen: true,
                    allowfullscreen: true,
                    borderColor: "transparent",
                    borderWidth: 0,
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: 'transparent'
                }}

                src={src}
                scrolling="no"
                allowFullScreen
            />
        </div>
    )
}

const DownloadResource = ({ url }) => {
    return (
      <div className="pt-10">
        <a
            className="bg-primary-500 hover:bg-primary-600 border-transparent rounded-md border px-10 py-3 text-base font-medium md:text-lg text-white"
            href={url}
            target='_blank'
        >
            Download
        </a>
      </div>
    )
}
export default function IframeCard(props) {
    const { state: progressContext, update: updateProgress } = useContext(ProgressContext);
    const [resource, setResource] = useState();

    useEffect(() => {
        if (props?.resource) {
            setResource(props.resource);
        }
    }, [props?.resource]);

    const handleClickFavorite = async () => {
        updateProgress({ isLoading: true });
        if (resource.isFavorited) {
            await favoriteMark(resource.id, '/favorite/favorite-user-unmark');
        } else {
            await favoriteMark(resource.id, '/favorite/favorite-user-mark');
        }
        updateProgress({ isLoading: false });
    };

    const favoriteMark = async (resourceId, url) => {
        try {
            const res = await RequestNextApi.post(url,
                {
                    resourceId,
                    jwtToken: getCookie('jwt-token')
                }
            );
            if (res.data && res.data.success) {
                ShowNotification.success(res.data.message);
                setResource({
                    ...resource,
                    isFavorited: !resource.isFavorited
                });
                return true;
            } else {
                ShowNotification.error(res.data.message);
                return false;
            }
        } catch (error) {
            ShowNotification.error('Marking favorite failed');
        }
    };

    const hasPDFResource = React.useMemo(() =>
        resource?.resourceType === 'pdf' &&
        resource?.resourceFileUrl?.split(".")?.[(resource?.resourceFileUrl?.split(".")?.length || 2) - 1] === 'pdf',
        [resource?.resourceType, resource?.resourceFileUrl]);

    return (
        <Card
            className={'flex flex-col'}
        >
            {(resource?.resourceType === 'pdf' || resource?.resourceType === 'file') ? hasPDFResource ? (
                <div style={{ height: '800px' }} className='m-3'>
                    <PDFViewer fileUrl={resource?.resourceFileUrl} />
                </div>
            ) : (
                <div className='p-6'>
                    <DownloadResource url={resource?.resourceFileUrl} />
                </div>
            ) : <></>}
            {resource?.resourceType !== 'pdf' && resource?.resourceType !== 'link' && (
                <IframeCardMedia
                    component="iframe"
                    height="auto"
                    scrolling='no'
                    src={resource?.resourceFileUrl}
                    alt="Resource"
                    className='border-0'
                    allowfullscreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                />
            )}
            <CardContent className={`flex flex-col`}>
                <div className='p-3'>
                    <div className='flex justify-between'>
                        <Typography
                            component="div"
                            className='text-secondary-500 text-xl'
                        >
                            {resource?.title}
                        </Typography>
                        {resource && !resource?.isFavorited && (
                            <FavoriteBorder
                                color='third'
                                className='cursor-pointer'
                                onClick={handleClickFavorite}
                            />
                        )}
                        {resource && resource?.isFavorited && (
                            <Favorite
                                color='third'
                                className='cursor-pointer'
                                onClick={handleClickFavorite}
                            />
                        )}
                    </div>
                    <Typography
                        className='text-orange-400 text-xl'
                        component='div'
                    >
                        {resource?.speakers[0]?.speakerName}
                    </Typography>
                    <Typography
                        gutterBottom
                        component='div'
                        className='text-xl'
                    >
                        {parse(resource?.description ?? '')}
                    </Typography>
                    {resource?.resourceType === 'link' && (
                        <a className="mt-3 text-secondary-500 text-xl underline" href={resource?.resourceFileUrl} target='_blank'>{resource?.resourceFileText}</a>
                    )}
                </div>
            </CardContent>
        </Card>
    )
}
